const shrekPhrases = ["shrek", "fiona"];
const shrekColor: string = "#4CAF50";


export const getCustomBackgroundColor = (currentPhrase: string) => {
    if (shrekPhrases.includes(currentPhrase)) {
        return shrekColor;
    }

    return undefined;
}