export function pickRandomPhrase(array: string[], except?: string) {
    const randomIndex = Math.floor(Math.random() * array.length);
    if (!except) {
        return array[randomIndex];
    }

    if (array.filter(x => x !== except).length === 0) {
        return array[randomIndex];
    }

    let newWord = array[randomIndex];
    while (except === newWord) {
        const newIndex = Math.floor(Math.random() * array.length);
        newWord = array[newIndex];
    }

    return newWord;
}