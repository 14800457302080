export const PRIMARY_BUTTON = '#3366CC';
export const PRIMARY_BUTTON_BORDER = '#004080';
export const PRIMARY_BUTTON_DISABLED = '#AAB8CC';
export const SECONDARY_BUTTON = '#FAF0E6'
export const PRIMARY_INPUT_BACKGROUND = '#F5F5F5';
export const PRIMARY_INPUT_BORDER = '#CCCCCC';
export const PRIMARY_INPUT_PLACEHOLDER = '#999999';
export const SUCCESS_GREEN = '#A2DED0';
export const SUCCESS_GREEN_BORDER = '#8CCCBF';
export const BACKGROUND = '#FFEFD5';
export const BACKGROUND_RGB = 'rgba(229, 229, 229, 1)';
export const SHARE_BLUE = '#225cf0';

export const WINNER_GOLD = '#d4af37';
export const BLUE_INK = '#191970';

// TeamsColors
export const BLUE_TEAM = '#3498db';
export const BLUE_TEAM_BORDER = '#2980b9';
export const RED_TEAM = '#eaa7b2';
export const RED_TEAM_BORDER = '#ffa07a';
export const GREEN_TEAM = '#2ecc71';
export const GREEN_TEAM_BORDER = '#27ae60';
export const WHITE_HINT = 'rgba(255, 255, 255, 0.5)';

// Game buttons
export const WRONG_RED = '#e74c3c';
export const WRONG_RED_BORDER = '#c73223';
export const SKIP_GRAY = '#babdc2';
export const SKIP_GRAY_BORDER = '#a4a7ad';
export const OK_GREEN = '#98fb98';
export const OK_GREEN_BORDER = '#87d987';

// Modes
export const QUICK_PLAY_BACKGROUND = '#FF6347';
export const QUICK_PLAY_BORDER = '#BF360C';
export const CLASSIC_MODE_BACKGROUND = '#3498db';
export const CLASSIC_MODE_BORDER = '#2980b9';