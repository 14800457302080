import {BLUE_TEAM, BLUE_TEAM_BORDER, GREEN_TEAM, GREEN_TEAM_BORDER, RED_TEAM, RED_TEAM_BORDER} from "../themes/colors";

export type TeamColor = {
    background: string;
    border: string;
}

export const teamColors: TeamColor[] = [{
    background: BLUE_TEAM,
    border: BLUE_TEAM_BORDER
}, {
    background: RED_TEAM,
    border: RED_TEAM_BORDER
}, {
    background: GREEN_TEAM,
    border: GREEN_TEAM_BORDER
}]