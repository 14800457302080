export type Rule = {
    header?: string;
    content: string;
    type: RuleType,
    roundNumber?: number;
}

export enum RuleType {
    ContentOnly,
    HeaderWithContent,
    RoundDescription
}