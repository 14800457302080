import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { EnterPhrases } from './components/EnterPhrases/EnterPhrases';
import { ErrorPage } from './components/Error/ErrorPage';
import { PhrasesSaved } from './components/PhrasesSaved/PhrasesSaved';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Support } from './components/Support/Support';
import { Welcome } from './components/Welcome/Welcome';
import { EnterPlayerNames } from './components/EnterPlayerNames/EnterPlayerNames';
import {EnterCategories} from "./components/EnterCategories/EnterCategories";
import {ModeSelection} from "./components/ModeSelection/ModeSelection";
import {PhrasesNumber} from "./components/PhrasesNumber/PhrasesNumber";
import React from "react";
import {Box, Card, Stack, ThemeProvider} from "@mui/material";
import theme from "./themes/default.theme";
import {DrawnTeams} from "./components/DrawnTeams/DrawnTeams";
import {Game} from "./components/Game/Game";
import {RankingResult} from "./components/RankingResult/RankingResult";
import {SharePhraseLinks} from "./components/SharePhraseLinks/SharePhraseLinks";
import {Rules} from "./components/Rules/Rules";
import {PL, US} from "country-flag-icons/react/1x1";
import {Settings} from "@mui/icons-material";
import {GameSettings} from "./components/Settings/GameSettings";

function App() {

  const userLanguage = navigator.language.split(/[-_]/)[0];
  const getLanguageEmoji = (lang: string) => {
    if (lang.toLowerCase() === "pl") {
      return "🇵🇱";
    }
    if (lang.toLowerCase() === "en") {
      return "🇬🇧🇬"
    }

    return "";
  }
  
  i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./locales/en.json'),
      },
      pl: {
        translation: require('./locales/pl.json'),
      },
      // Add more languages as needed
    },
    lng: userLanguage, // Set the initial language based on the user's location
    fallbackLng: 'en', // Fallback language in case the selected language is not available
    interpolation: {
      escapeValue: false,
    },
  }).then(() => {
    console.log(
        "Ready, steady, go. 🤪 Current language: "
        + i18n.language
        + " "
        + getLanguageEmoji(i18n.language)
    );
  });

  const changeLanguage = (lang: string) => {
    if( i18n.language.toLowerCase() === lang.toLowerCase() ) {
      return;
    }

    i18n.changeLanguage(lang).then(() => {
      console.log(
          "Ready, steady, go. 🤪 Current language: "
          + i18n.language
          + " "
          + getLanguageEmoji(i18n.language)
      );
    });
  }

  return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Box sx={{ minHeight: '100%', paddingBottom: '70px' }}>
              <Routes>
                <Route path="*" Component={Welcome}></Route>
                <Route path="/welcome" Component={Welcome}></Route>
                <Route path="/EnterPlayerNames" Component={EnterPlayerNames}></Route>
                <Route path="/EnterCategories" Component={EnterCategories}></Route>
                <Route path="/ModeSelection" Component={ModeSelection}></Route>
                <Route path="/PhrasesNumber" Component={PhrasesNumber}></Route>
                <Route path="/DrawnTeams" Component={DrawnTeams}></Route>
                <Route path="/Game" Component={Game}></Route>
                <Route path="/Ranking" Component={RankingResult}></Route>
                <Route path="/SharePhraseLinks" Component={SharePhraseLinks}></Route>
                <Route path="/Rules" Component={Rules}></Route>
                <Route path="/Settings" Component={GameSettings} />
                { /* Phrase entrance */ }
                <Route path="/error" Component={ErrorPage}></Route>
                <Route path="/success" Component={PhrasesSaved}></Route>
                <Route path="/:gameId/:userId" Component={EnterPhrases}/>
                <Route path='/support' Component={Support}/>
              </Routes>
            </Box>
          <Box sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '50px'
          }}>
            <Card
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#FAF0E6',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }} >
              <Stack direction="row" spacing={2} sx={{justifyContent: 'space-between'}}>
                <US
                    onClick={e => changeLanguage("en")}
                    title={"English (US)"}
                    width={"30px"}
                    style={{ borderRadius: 10, cursor: "pointer" }}
                />
                <PL
                    onClick={e => changeLanguage("pl")}
                    title={"Polish"}
                    width={"30px"}
                    style={{ borderRadius: 10, marginRight: 10, cursor: "pointer" }}
                />
              </Stack>
            </Card>
          </Box>
        </BrowserRouter>
      </ThemeProvider>

  );
}

export default App;
