import {UrlOwner} from "../models/urlOwner";

export const getUrlsForBulkShare = (initialText: string, urls: UrlOwner[]) => {
    if (urls.length === 0) {
        return '';
    }

    let str = initialText + '\n';

    urls.forEach((url) => {
        str += `\n${url.owner} - ${url.url}\n`;
    });

    return str;
}