import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    LETTERS_DIGITS_ONLY_REGEX,
    MAX_CATEGORIES, MIN_CATEGORIES,
    MIN_PLAYERS
} from "../../constans/rules";
import {Routes} from "../../constans/routes";
import {useLocation, useNavigate} from "react-router-dom";
import {EnterCategoriesView} from "./EnterCategoriesView";
import {BaseModalProps} from "../BaseModal/BaseModal.props";
import {BaseModal} from "../BaseModal/BaseModal";

export const EnterCategories: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const header = t('categoriesHeader');
    const subHeader = t('hintsBtn');
    const confirmButtonLabel = t('nextBtn');
    const cancelButtonLabel = t('previousBtn');
    const addBtnLabel = t('addBtn');
    const removeBtnLabel = t('removeBtn');
    const defaultCategoryPlaceholders = [
        t('defaultCategory1'),
        t('defaultCategory2'),
        t('defaultCategory3'),
    ]
    const [someCategoriesInvalid, setSomeCategoriesInvalid] = useState(false);
    const [playerNames, setPlayerNames] = useState<string[]>(Array.from({ length: MIN_PLAYERS }, () => ''));
    const [categories, setCategories] = useState<string[]>(Array.from({ length: MAX_CATEGORIES }, () => ''));
    const [increaseCategoriesNumberDisabled, setIncreaseCategoriesNumberDisabled] = useState(false);
    const [decreaseCategoriesNumberDisabled, setDecreaseCategoriesNumberDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<BaseModalProps>({
        header: '',
        message: '',
        showHintIcon: false,
        okAction: {
            action: () => setIsModalVisible(false),
            label: t('defaultOkBtn')
        }
    });

    const onConfirm = async () => {
        setIsLoading(true);

        navigate(Routes.Settings, {
            state: {
                playerNames: playerNames.map((name: string) => name.trim()),
                categories: categories.map((cat: string) => cat.trim()),
            }
        })

        setIsLoading(false);
    };

    const onCancel = () => {
        navigate(Routes.ModeSelection, {
            state: {
                playerNames: playerNames,
                playersNumber: playerNames.length
            }
        });
    };

    const onCategoryChanged = (text: string, index: number) => {
        if (!LETTERS_DIGITS_ONLY_REGEX.test(text)) {
            return;
        }
        setCategories([
            ...categories.slice(0, index),
            text,
            ...categories.slice(index + 1)
        ]);
    };

    const onCategoriesNumberIncreased = () => {
        if (categories.length === MAX_CATEGORIES) {
            return;
        }

        setCategories([...categories, '']);
    }

    const onCategoriesNumberDecreased = () => {
        if (categories.length === MIN_CATEGORIES) {
            return;
        }

        setCategories(categories.slice(0, -1));
    }

    const hasDuplicateCategories = (categories: string[]) => {
        const lowercaseSet = new Set<string>();

        for (const str of categories) {
            const lowercaseStr = str.toLowerCase();

            if (lowercaseSet.has(lowercaseStr)) {
                // Duplicate found
                return true;
            }

            lowercaseSet.add(lowercaseStr);
        }

        // No duplicates found
        return false;
    }

    const onShowHints = () => {
        setModalContent({
            header: t('hintsBtn'),
            message: t('categoriesHint'),
            showHintIcon: true,
            okAction: {
                action: () => {
                    setIsModalVisible(false);
                },
                label: t('okBtn')
            }
        });
        setIsModalVisible(true);
    };

    useEffect(() => {
        const state = location.state as {
            playerNames: string[],
            categories?: string[]
        };
        setPlayerNames(state.playerNames);
        if (state.categories) {
            setCategories(state.categories);
        }
    }, [location.state]);

    useEffect(() => {
        setSomeCategoriesInvalid(categories.some(item => item.length < 2) || hasDuplicateCategories(categories));
    }, [categories]);

    useEffect(() => {
        setDecreaseCategoriesNumberDisabled(categories.length === MIN_CATEGORIES);
        setIncreaseCategoriesNumberDisabled(categories.length === MAX_CATEGORIES);
    }, [categories])

    return <>
        <EnterCategoriesView
            removeBtnLabel={removeBtnLabel}
            addBtnLabel={addBtnLabel}
            categoryPlaceholderLabels={defaultCategoryPlaceholders}
            onCategoriesNumberIncreased={onCategoriesNumberIncreased}
            onCategoriesNumberDecreased={onCategoriesNumberDecreased}
            increaseCategoriesNumberDisabled={increaseCategoriesNumberDisabled}
            decreaseCategoriesNumberDisabled={decreaseCategoriesNumberDisabled}
            someCategoriesInvalid={someCategoriesInvalid || isLoading}
            onCategoryChanged={onCategoryChanged}
            onShowHints={onShowHints}
            categories={categories}
            header={header}
            subHeader={subHeader}
            confirmButtonLabel={confirmButtonLabel}
            cancelButtonLabel={cancelButtonLabel}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
        <BaseModal {...modalContent} isVisible={isModalVisible} />
    </>
}