import React from "react";
import {ModalProps} from "./BaseModal.props";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Stack, Typography} from "@mui/material";
import Lottie from "lottie-react";
import ideaIcon from "../../assets/animations/idea.json";
import {TransitionProps} from "@mui/material/transitions";
import {PrimaryButton} from "../../styled-components/styledComponents";

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BaseModal: React.FC<ModalProps> = ({
    isVisible,
    header,
    message,
    cancelAction,
    okAction,
    showHintIcon
}) => {
    return (
        <Dialog open={isVisible}
                TransitionComponent={Transition}
                maxWidth={"md"}
                sx={{ maxHeight: 435 }}
                >
            <DialogTitle sx={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                display: "flex",
            }}>
                {
                    showHintIcon
                        ?   <div style={{ width: "20%" }} >
                             <Lottie animationData={ideaIcon} loop={true}/>
                            </div>
                        : <Typography variant={"h6"}>{header}</Typography>
                }
            </DialogTitle>
            <DialogContent>
                <Stack
                    alignContent={"center"}
                    spacing={2}
                    sx={{ textAlign: "center" }}
                >
                    <Typography variant={"body1"}>
                        {message}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
                <PrimaryButton variant={"contained"} onClick={okAction.action}>{okAction.label}</PrimaryButton>
                { cancelAction && <PrimaryButton variant={"contained"} onClick={cancelAction.action}>{cancelAction.label}</PrimaryButton>}
            </DialogActions>

        </Dialog>
    )
}