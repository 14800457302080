import {LoadingModalProps} from "./LoadingModal.props";
import React from "react";
import {Dialog, DialogContent} from "@mui/material";
import loadingIcon from "../../assets/animations/cooking_pot.json";
import Lottie from "lottie-react";

export const LoadingModal: React.FC<LoadingModalProps> = ({isLoading}) => {
    // TODO: Do dopracowania bo brzydkie jak noc bezgwiezdna

    return <Dialog
        open={isLoading ?? false}
        sx={{ backgroundColor: "transparent" }}
    >
        <DialogContent sx={{ backgroundColor: "black" }}>
            <Lottie style={{ width: "100px"}} animationData={loadingIcon} autoplay loop />
        </DialogContent>

    </Dialog>
}