import {RankingResultProps} from "./RankingResult.props";
import React from "react";
import {Box, List, ListItem, Stack, Typography} from "@mui/material";
import {PlayerRankingModel, TeamRankingModel} from "../../models/ranking";
import {MainStack, PrimaryButton} from "../../styled-components/styledComponents";

export const RankingResultView: React.FC<RankingResultProps> = ({
    ranking,
    ptsLabel,
    scoreboardLabel,
    endGameLabel,
    onEndGame
}) => {
    const renderTeamRankingItem = (r: TeamRankingModel, i: number) => (
        <ListItem>
            <Typography variant={"body1"}>{ r.rank + '. ' + r.teamName + ' - ' + r.score + ptsLabel }</Typography>
        </ListItem>
    )

    const renderPlayerRankingItem = (r: PlayerRankingModel, i: number) => (
        <ListItem>
            <Typography variant={"body1"}>{ r.rank + '. ' + r.playerName + ' - ' + r.score + ptsLabel }</Typography>
        </ListItem>
    )

    return <MainStack spacing={2} alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant={"h6"} textAlign={"center"}>{ scoreboardLabel }</Typography>
            </Box>
            <Stack spacing={2} direction="row" textAlign="center">
                <Stack>
                    <List>
                        { ranking.teamRanking.map((r, i) => renderTeamRankingItem(r, i)) }
                    </List>
                </Stack>
                <Stack>
                    <List>
                        { ranking.playerRanking.map((r, i) => renderPlayerRankingItem(r, i)) }
                    </List>
                </Stack>
            </Stack>
            <PrimaryButton variant={"contained"} onClick={onEndGame}>{endGameLabel}</PrimaryButton>
        </MainStack>
}