import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {InitializeGameResponse} from "../../models/initializeGameResponse";
import {BaseModalProps} from "../BaseModal/BaseModal.props";
import {DEFAULT_TEAMS, INCREASED_TEAMS, MIN_PLAYERS_FOR_3_TEAMS_SUGGESTION} from "../../constans/rules";
import {getUrlsForBulkShare} from "../../functions/getUrlsForBulkShare";
import {Routes} from "../../constans/routes";
import {GameModes} from "../../models/gameMode";
import {deleteGameData, drawTeams} from "../../api/apiService";
import {SharePhraseLinksView} from "./SharePhraseLinksView";
import {BaseModal} from "../BaseModal/BaseModal";
import {LoadingModal} from "../LoadingModal/LoadingModal";
import {Snackbar} from "@mui/material";

export const SharePhraseLinks: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const initializeData: InitializeGameResponse = location.state as InitializeGameResponse;
    const [shared, setShared] = useState(Array.from({ length: initializeData.urls.length }, () => false));
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [teamsNumber, setTeamsNumber] = useState(DEFAULT_TEAMS);
    const [startGame, setStartGame] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [modalContent, setModalContent] = useState<BaseModalProps>({
        header: '',
        message: '',
        showHintIcon: false,
        okAction: {
            action: () => setIsModalVisible(false),
            label: t('defaultOkBtn')
        }
    });

    const shareButtonText = t('shareLabel');
    const confirmButtonLabel = t('nextBtn');
    const cancelButtonLabel = t('teamQuit');
    const header = t('sharePhraseLinksHeader');
    const subHeader = t('hintsBtn');
    const alertTitle = t('areYouSure');
    const alertNoAllShared = t('sharePhraseAlert');
    const alertConfirm = t('nextBtn');
    const alertCancel = t('cancelBtn');
    const alertAllChangesLost = t('allChangesWillBeLost');
    const alertOk = t('okBtn');
    const bulkShareLabel = t('bulkShareLabel');
    const copiedLabel = t('copied');

    const bulkShare = async () => {
        const stringToShare = getUrlsForBulkShare(t('bulkShareText'), initializeData.urls);

        try {
            await navigator.clipboard.writeText(stringToShare);
            setShared(Array.from({ length: initializeData.urls.length }, () => true));
            setSnackbarOpen(true);
        } catch (err) {
            console.error("Coś się popsuło i się nie skopiowało :o");
        }
    }

    const shareButtonClick = async (url: string, index: number) => {
        try {
            await navigator.clipboard.writeText(url);
            setShared([
                ...shared.slice(0, index),
                true,
                ...shared.slice(index + 1)
            ]);
            setSnackbarOpen(true);
        } catch (err) {
            console.error("Coś się popsuło i się nie skopiowało :o");
        }
    }

    const deleteGame = async () => {
        setIsLoading(true);
        await deleteGameData(initializeData.gameId);
        setIsModalVisible(false);
        setIsLoading(false);
        navigate(Routes.Welcome);
    }

    const onCancel = async () => {
        setModalContent({
            header: alertTitle,
            message: alertAllChangesLost,
            showHintIcon: false,
            okAction: {
                action: async () => {
                    await deleteGame();
                },
                label: alertOk
            },
            cancelAction: {
                label: alertCancel,
                action: () => {
                    setIsModalVisible(false);
                }
            }
        });
        setIsModalVisible(true);
    };


    const askTeamsNumber = () => {
        if (initializeData.urls.length < MIN_PLAYERS_FOR_3_TEAMS_SUGGESTION) {
            setTeamsNumber(DEFAULT_TEAMS);
            setStartGame(true);
            return;
        }

        setModalContent({
            header: t('selectTeamsNumberHeader'),
            message: t('selectTeamsNumberMessage')
                .replace('{0}', MIN_PLAYERS_FOR_3_TEAMS_SUGGESTION.toString())
                .replace('{1}', DEFAULT_TEAMS.toString())
                .replace('{2}', INCREASED_TEAMS.toString()),
            showHintIcon: false,
            okAction: {
                action: () => {
                    setTeamsNumber(INCREASED_TEAMS);
                    setIsModalVisible(false);
                    setStartGame(true);
                },
                label: t('selectTeamsBtn')
                    .replace('{0}', INCREASED_TEAMS.toString())
            },
            cancelAction: {
                label:t('selectTeamsBtn')
                    .replace('{0}', DEFAULT_TEAMS.toString()),
                action: async () => {
                    setTeamsNumber(DEFAULT_TEAMS);
                    setIsModalVisible(false);
                    setStartGame(true);
                }
            }
        });

        setIsModalVisible(true);
    };

    const onConfirm = useCallback(() => {
        if (shared.some(s => !s)) {
            setModalContent({
                header: alertTitle,
                message: alertNoAllShared,
                showHintIcon: false,
                okAction: {
                    action: () => {
                        setIsModalVisible(false);
                        askTeamsNumber();
                    },
                    label: alertConfirm
                },
                cancelAction: {
                    label: alertCancel,
                    action: () => {
                        setIsModalVisible(false);
                    }
                }
            });
            setIsModalVisible(true);
            return;
        }

        askTeamsNumber();
    }, [shared]);

    const onShowHints = () => {
        setModalContent({
            header: t('hintsBtn'),
            message: t('shareHint'),
            showHintIcon: true,
            okAction: {
                action: () => {
                    setIsModalVisible(false);
                },
                label: alertOk
            }
        });
        setIsModalVisible(true);
    };

    const navigateDrawTeams = useCallback(async () => {
        setIsLoading(true);
        const response = await drawTeams({
            gameId: initializeData.gameId,
            teamsNumber
        });

        if (response) {
            setIsLoading(false);
            const navigationParams = {
                ...response,
                mode: GameModes.Classic,
                skips: location.state.skips,
                roundSeconds: location.state.roundSeconds
            }
            navigate(Routes.DrawnTeams, { state: navigationParams });
            return;
        }

        setIsLoading(false);
    }, [initializeData.gameId, teamsNumber, navigate]);

    const onSnackbarClose = () => {
        setSnackbarOpen(false);
    }


    useEffect(() => {
        if (!startGame) {
            return;
        }

        setTimeout(() => {
            navigateDrawTeams();
        }, 500);

    }, [navigateDrawTeams, startGame, teamsNumber]);

    return <>
        <SharePhraseLinksView header={header}
                              subHeader={subHeader}
                              shared={shared}
                              onCancel={onCancel}
                              onConfirm={onConfirm}
                              onShowHints={onShowHints}
                              confirmButtonLabel={confirmButtonLabel}
                              cancelButtonLabel={cancelButtonLabel}
                              shareButtonText={shareButtonText}
                              bulkShareLabel={bulkShareLabel}
                              urlOwners={initializeData.urls}
                              bulkShare={bulkShare}
                              onShare={shareButtonClick}/>
        <BaseModal {...modalContent} isVisible={isModalVisible} />
        { isLoading && <LoadingModal isLoading={isLoading} /> }
        <Snackbar message={copiedLabel} open={snackbarOpen} autoHideDuration={2000} onClose={onSnackbarClose}/>
    </>
}