import {RankingResultView} from "./RankingResultView";
import {useLocation, useNavigate} from "react-router-dom";
import {Ranking} from "../../models/ranking";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {Routes} from "../../constans/routes";

export const RankingResult: React.FC = ({}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const ptsLabel = t('pts');
    const scoreboardLabel = t('scoreboard');
    const endGameLabel = t('endGame');

    const ranking = location.state as Ranking;

    useEffect(() => {
        if (!ranking) {
            navigate(Routes.Error);
        }
    }, [navigate, ranking]);

    useEffect(() => {
        // TODO: Add adds?
        // const interstitial = InterstitialAd.createForAdRequest(FULL_AD_UNIT_ID, {
        //     requestNonPersonalizedAdsOnly: true,
        // });
        //
        // interstitial.addAdEventListener(AdEventType.LOADED, () => {
        //     interstitial.show();
        // });
        // interstitial.load();
    }, []);

    const onEndGame = () => {
        navigate(Routes.Welcome);
    };

    return (
        <RankingResultView
            ptsLabel={ptsLabel}
            endGameLabel={endGameLabel}
            scoreboardLabel={scoreboardLabel}
            ranking={ranking}
            onEndGame={onEndGame}
        />
    )

}