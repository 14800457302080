import {PresenterChangeModalProps} from "./PresenterChangeModal.props";
import React from "react";
import {Transition} from "../BaseModal/BaseModal";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {PrimaryButton} from "../../styled-components/styledComponents";

export const PresenterChangeModal: React.FC<PresenterChangeModalProps> = ({
  isVisible,
  title,
  subTitle,
  content,
  color,
  okButtonLabel,
  okCallback
}) => {
    return <Dialog open={isVisible}
                   fullScreen={true}
                   TransitionComponent={Transition}
                   maxWidth={"md"}
                   PaperProps={{
                       style: {
                           backgroundColor: color?.background,
                           borderColor: color?.border,
                       }
                   }}>
        <DialogTitle sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            display: "flex",
        }}>
            {title}
        </DialogTitle>
        <DialogContent sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            display: "flex",
        }}>
            <Stack
                alignContent={"center"}
                spacing={2}
                sx={{ textAlign: "center" }}
            >
                <Typography variant={"body1"}>
                    {content}
                </Typography>
                <Typography variant={"body1"}>
                    {subTitle}
                </Typography>
            </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
            <PrimaryButton variant={"contained"} onClick={okCallback}>{okButtonLabel}</PrimaryButton>
        </DialogActions>
    </Dialog>
}