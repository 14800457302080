import React from "react";
import {PhrasesNumberProps} from "./PhrasesNumber.props";
import {Link, Slider, Stack, Typography} from "@mui/material";
import {MainStack, PrimaryButton} from "../../styled-components/styledComponents";

export const PhrasesNumberView: React.FC<PhrasesNumberProps> = ({
    confirmButtonLabel,
    cancelButtonLabel,
    maxPhrasesNumber,
    minPhrasesNumber,
    phrasesNumber,
    phrasesNumberLabel,
    header,
    subHeader,
    onCancel,
    onConfirm,
    onChange,
    onShowHints
}) => {
    return <MainStack spacing={2} alignItems={ 'center'}>
            <Typography
                align={'center'}
                variant={'h5'}
            >
                {header}
            </Typography>
            <Link>
                <Typography
                    align={'center'}
                    variant={'h6'}
                    onClick={onShowHints}
                >
                    {subHeader}
                </Typography>
            </Link>
            <Typography
                style={{
                    marginBlock: 40
                }}
                align={'center'}
                variant={'h6'}
            >
                {phrasesNumberLabel}
            </Typography>
            <Slider
                onChange={onChange}
                min={minPhrasesNumber}
                max={maxPhrasesNumber}
                step={1}
                style={{
                    width: 250,
                }}
                valueLabelDisplay="on"
                value={phrasesNumber}
            />
            <PrimaryButton
                variant="contained"
                style={{
                    width: 200,
                    marginTop: 120,
                }}
                onClick={onConfirm}>
                {confirmButtonLabel}
            </PrimaryButton>
            <PrimaryButton
                variant="contained"
                style={{
                    width: 200
                }}
                onClick={onCancel}
            >
                {cancelButtonLabel}
            </PrimaryButton>
        </MainStack>
}