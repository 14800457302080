import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {ModeSelectionView} from "./ModeSelectionView";
import {Routes} from "../../constans/routes";
import {BaseModalProps} from "../BaseModal/BaseModal.props";
import {BaseModal} from "../BaseModal/BaseModal";

export const ModeSelection: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [playerNames, setPlayerNames] = React.useState<string[]>([]);
    const [playersNumber, setPlayersNumber] = React.useState<number | undefined>();
    const classicModeLabel = t('classicMode');
    const quickPlayModeLabel = t('quickPlayMode');
    const cancelButtonLabel = t('previousBtn');
    const header = t('selectMode');
    const subHeader = t('hintsBtn');
    const modalHintHeader = t('modeHintHeader');
    const modalHintContent = t('modeHintContent');

    useEffect(() => {
        const state = location.state as {
            playerNames: string[],
            playersNumber?: number
        };
        setPlayerNames(state.playerNames);
        setPlayersNumber(state.playersNumber);
    }, [location.state]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<BaseModalProps>({
        header: '',
        message: '',
        showHintIcon: false,
        okAction: {
            action: () => setIsModalVisible(false),
            label: t('defaultOkBtn')
        }
    });

    const onClassicModeSelected = () => {

        // navigate(Routes.EnterCategories, {
        //     ...route.params,
        //     mode: GameModes.Quick
        // });

        // add Params
        navigate(Routes.EnterCategories, {
            state: {
                playerNames: playerNames,
            }
        });

    }

    const onQuickModeSelected = () => {
        navigate(Routes.PhrasesNumber, { state: { playerNames: playerNames } });
    }

    const onCancel = () => {
        navigate(Routes.EnterPlayerNames, {
            state: {
                playerNames: playerNames,
                playersNumber: playersNumber
            }
        });
    }

    const onShowHints = () =>{
        setModalContent({
            header: '',
            message: modalHintHeader + '\n\n' + modalHintContent,
            showHintIcon: true,
            okAction: {
                action: () => {
                    setIsModalVisible(false);
                },
                label: t('okBtn')
            }
        });
        setIsModalVisible(true);
    };

    return <>
        <ModeSelectionView
            classicModeLabel={classicModeLabel}
            quickPlayModeLabel={quickPlayModeLabel}
            cancelButtonLabel={cancelButtonLabel}
            onClassicModeSelected={onClassicModeSelected}
            onQuickPlayModeSelected={onQuickModeSelected}
            onCancel={onCancel}
            onShowHints={onShowHints}
            header={header}
            subHeader={subHeader}
        />
        <BaseModal {...modalContent} isVisible={isModalVisible} />
    </>
}