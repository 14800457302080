import {Button, Card, Stack, styled, TextField} from "@mui/material";
import {PRIMARY_INPUT_BACKGROUND, PRIMARY_INPUT_BORDER} from "../themes/colors";

export const PrimaryButton = styled(Button)`
    border-radius: 1.5rem;
    border-width: 2px;
    border-style: solid;
    letter-spacing: 0.1cap;
    padding: 0.5rem;
`;

export const RoundedCard = styled(Card)`
    border-radius: 1.5rem;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.1);
`;

export const PrimaryInput = styled(TextField)`
    border-radius: 1.5rem;
    border-width: 2px;
    border-style: solid;
    letter-spacing: 0.1cap;
    padding: 0.1rem;
    background-color: ${PRIMARY_INPUT_BACKGROUND};
    border-color: ${PRIMARY_INPUT_BORDER};
    outline: none;
    & fieldset { border: none }
`;

export const MainStack = styled(Stack)`
    margin-top: 3rem;
`;