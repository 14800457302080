import React, {useEffect, useRef, useState} from "react";
import {GameModes} from "../../models/gameMode";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Team} from "../../models/team";
import {BaseModalProps} from "../BaseModal/BaseModal.props";
import {Routes} from "../../constans/routes";
import {DEFAULT_TEAMS} from "../../constans/rules";
import {drawTeamsOffline} from "../../functions/drawTeamsOffline";
import {DrawnTeamsView} from "./DrawnTeamsView";
import {BaseModal} from "../BaseModal/BaseModal";
import {deleteGameData, getAllEntered} from "../../api/apiService";

export const DrawnTeams: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const mode: GameModes = location.state.mode;
    const checkInterval = 5000;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [teams, setTeams] = useState<Team[]>([]);
    const [gameId, setGameId] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [areTeamNamesEntered, setAreTeamNameEntered] = useState(false);
    const isGameReadyInterval = useRef<NodeJS.Timeout | undefined>(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<BaseModalProps>({
        header: '',
        message: '',
        showHintIcon: false,
        okAction: {
            action: () => setIsModalVisible(false),
            label: t('defaultOkBtn')
        }
    });


    const confirmButtonLabelWaiting = t('teamWaitingLabel');
    const confirmButtonLabelReady = t('teamReadyLabel');
    const cancelButtonLabel = mode === GameModes.Quick ? t('previousBtn') : t('teamQuit');
    const alertTitle = t('areYouSure');
    const alertMessage = t('allChangesWillBeLost');
    const alertCancel = t('cancelBtn');
    const alertConfirm = t('teamQuit');
    const teamPlaceholder = t('teamPlaceholder');
    const subHeader = t('hintsBtn');
    const header = t('teamResultHeader');

    const clearGameData = async () => {
        if (mode !== GameModes.Classic) {
            return;
        }
        setIsLoading(true);
        await deleteGameData(location.state.gameId);
        setIsLoading(false);
    }

    const onCancel = async () => {
        if (mode === GameModes.Quick) {
            navigate(Routes.PhrasesNumber, {
                state: location.state
            });
            return;
        }
        setModalContent({
            header: alertTitle,
            message: alertMessage,
            showHintIcon: false,
            okAction: {
                action: async () => {
                    await clearGameData();
                    setIsModalVisible(false);
                    navigate(Routes.Welcome);
                },
                label: alertConfirm
            },
            cancelAction: {
                label: alertCancel,
                action: () => {
                    setIsModalVisible(false);
                }
            }
        });
        setIsModalVisible(true);
    };

    const onTeamNameChanged = (text: string, index: number) => {
        const team = teams[index];
        team.teamName = text;

        setTeams([
            ...teams.slice(0, index),
            team,
            ...teams.slice(index + 1)
        ]);
    }

    const onConfirm =  () => {
        const data = {
            gameId: gameId,
            teams: teams,
            mode: mode,
            phrasesNumber: location.state.phrasesNumber,
            skips: location.state.skips,
            roundSeconds: location.state.roundSeconds
        };
        navigate(Routes.Game, {
            state: data
        });
    };

    const onShowHints = () => {
        setModalContent({
            header: subHeader,
            message: t('teamResultHint'),
            showHintIcon: true,
            okAction: {
                action: () => {
                    setIsModalVisible(false);
                },
                label: t('okBtn')
            }
        });
        setIsModalVisible(true);
    };

    useEffect(() => {
        const routeData = location.state;
        if (teams.length > 0) {
            return;
        }

        const drawnTeams: Team[] = mode === GameModes.Quick
            ? drawTeamsOffline(routeData.playerNames,
                routeData.teamsNumber ?? DEFAULT_TEAMS)
            : routeData.teams;

        setGameId(routeData.gameId ?? "offline");

        drawnTeams.forEach((team) => {
            const teamsCopy = teams;
            teamsCopy.push({
                teamName: '',
                teamId: team.teamId,
                users: team.users
            });
            setTeams(teamsCopy)
        });

    }, [location.state, mode, teams]);

    useEffect(() => {
        if (isReady
            || gameId?.length === 0
            || isGameReadyInterval.current) {
            return;
        }

        if (mode === GameModes.Quick) {
            setIsReady(true);
            return;
        }

        isGameReadyInterval.current = setInterval(async () => {
            const result = await getAllEntered(gameId);

            if (result) {
                setIsReady(true);
                clearInterval(isGameReadyInterval.current);
                isGameReadyInterval.current = undefined;
            }
        }, checkInterval);

        return () => {
            clearInterval(isGameReadyInterval.current);
            isGameReadyInterval.current = undefined;
        }
    }, [gameId, isReady, mode]);

    useEffect(() => {
        const allValid = teams.every(team => team.teamName.length > 0);
        setAreTeamNameEntered(allValid)
    }, [teams]);

    return <>
        <DrawnTeamsView
            subHeader={subHeader}
            header={header}
            teamPlaceholder={teamPlaceholder}
            confirmButtonLabelReady={confirmButtonLabelReady}
            confirmButtonLabelWaiting={confirmButtonLabelWaiting}
            cancelButtonLabel={cancelButtonLabel}
            isReady={isReady}
            areTeamNamesEntered={areTeamNamesEntered}
            onCancel={onCancel}
            onConfirm={onConfirm}
            onShowHints={onShowHints}
            onTeamNameChanged={onTeamNameChanged}
            teams={teams}
        />
        <BaseModal {...modalContent} isVisible={isModalVisible} />
    </>
}