import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {GameModes} from "../../models/gameMode";
import {Team} from "../../models/team";
import {useTranslation} from "react-i18next";
import {DEFAULT_ROUND_SECONDS, DEFAULT_SKIPS_PER_TEAM, Rounds} from "../../constans/rules";
import {Presenter} from "../../models/presenter";
import {PlayerRanking} from "../../models/playerRanking";
import {BaseModalProps} from "../BaseModal/BaseModal.props";
import {PlayerRankingModel, Ranking, TeamRankingModel} from "../../models/ranking";
import {pickRandomPhrase} from "../../functions/pickRandomPhrase";
import {shuffleArray} from "../../functions/shuffleArray";
import {switchPresenter} from "../../functions/switchPresenter";
import correctAnimation from "../../assets/animations/correct.json";
import incorrectAnimation from "../../assets/animations/incorrect.json";
import {Routes} from "../../constans/routes";
import {GameView} from "./GameView";
import {formatTime} from "../../functions/formatTime";
import {getPhrases, getRandomPhrases} from "../../api/apiService";
import Lottie from "lottie-react";
import { Box } from "@mui/material";

export const Game: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const teams: Team[] = location.state.teams;
    const gameId: string = location.state.gameId;
    const mode: GameModes = location.state.mode;
    // const teams: Team[] = [{
    //     teamName: "Test1",
    //     teamId: '1',
    //     lastPresenterIndex: 0,
    //     users: ['Bartek', 'Ania']
    // },{
    //     teamName: "Test2",
    //     teamId: '2',
    //     lastPresenterIndex: 0,
    //     users: ['Grzesiek', 'Grzechu']
    // }];
    // const gameId = '12312';
    // const mode = GameModes.Classic;
    const [currentMode, setCurrentMode] = useState(Rounds.Describe);
    const timerInterval = useRef<NodeJS.Timeout | undefined>(undefined);
    const [presenterModalVisible, setPresenterModalVisible] = useState<boolean>(false);
    const [gameStarted, setGameStarted] = useState<boolean>(false);
    const [modeChangedModalVisible, setModeChangedModalVisible] = useState<boolean>(false);
    const [pauseModalVisible, setPauseModalVisible] = useState<boolean>(false);
    const [seconds, setSeconds] = useState<number | undefined>(undefined);
    const [currentPhrase, setCurrentPhrase] = useState('');
    const [guessedPhrases, setGuessedPhrases] = useState<string[]>([]);
    const [allPhrases, setAllPhrases] = useState<string[]>([]);
    const [currentPresenter, setCurrentPresenter] = useState<Presenter>({
        presenterName: teams[0].users[0],
        presenterIndex: 0,
        teamName: teams[0].teamName ,
        teamIndex: 0,
    });
    const [points, setPoints] = useState<number[]>(Array(teams.length).fill(0));
    const [playerRanking, setPlayerRanking] = useState<PlayerRanking[]>([]);
    const [skips, setSkips] = useState<number[] | undefined>(undefined);
    const [currentModeLabel, setCurrentModeLabel] = useState('');
    const [maxSkips, setMaxSkips] = useState<number>(0);

    // Animations
    const [animation, setAnimation] = useState<any>();
    const [showAnimation, setShowAnimation] = useState(false);

    // Alert for game quitting
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [alertContent, setAlertContent] = useState<BaseModalProps>({
        header: '',
        message: '',
        showHintIcon: false,
        okAction: {
            action: () => setIsConfirmModalVisible(false),
            label: t('defaultOkBtn')
        }
    });

    const presenterText = t('currentPresenter');
    const pointsLabel = t('points');
    const nextRoundLabel = t('nextRound');
    const startBtnLabel = t('startBtn');
    const okBtnLabel = t('okBtn');
    const alertTitle = t('areYouSure');
    const alertCancel = t('cancelBtn');
    const alertAllChangesLost = t('allChangesWillBeLost');
    
    useEffect(() => {
        if (skips) {
            return;
        }

        setSkips(Array(teams.length).fill(location.state?.skips ?? DEFAULT_SKIPS_PER_TEAM));
        setMaxSkips(location.state?.skips ?? DEFAULT_SKIPS_PER_TEAM);
    }, [location.state?.skips, skips, teams.length]);

    useEffect(() => {
        if (currentMode === Rounds.Describe) {
            setCurrentModeLabel(t('describingMode'));
        } else if (currentMode === Rounds.OneWord) {
            setCurrentModeLabel(t('oneWordMode'));
            changeModeModal(currentMode, t('oneWordMode'), t('step7des'));
        } else if (currentMode === Rounds.Show) {
            setCurrentModeLabel(t('showingMode'));
            changeModeModal(currentMode, t('showingMode'), t('step8des'));
        } else {
            setCurrentModeLabel(t('endGame'));
        }
    }, [currentMode, t]);

    useEffect(() => {
        if (teams?.length === 0 || playerRanking.length > 0) {
            return;
        }

        const users = teams.flatMap(x => x.users);
        const playerRankingInit: PlayerRanking[] = [];

        users.forEach(user => {
            playerRankingInit.push({
                playerName: user,
                points: 0
            })
        });

        setPlayerRanking(playerRankingInit);
    }, [teams, playerRanking]);

    useEffect(() => {
        if (seconds === undefined) {
            return;
        }
        timerInterval.current = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(timerInterval.current);
                switchTeams(currentPresenter);
                setSeconds(undefined);
            }
        }, 1000);

        // Clear the timer when the component unmounts
        return () => clearInterval(timerInterval.current);
    }, [seconds, currentPresenter]);

    useEffect(() => {
        if (!guessedPhrases || !guessedPhrases.length) {
            return;
        }
        if (guessedPhrases.length === allPhrases.length) {

            setSeconds(undefined);
            setCurrentMode(currentMode === Rounds.Describe
                ? Rounds.OneWord
                : currentMode === Rounds.OneWord
                    ? Rounds.Show
                    : Rounds.EndGame);

            return;
        }

        const phrasesLeft = getPhrasesToDraw();
        const newCurrentPhrase = pickRandomPhrase(phrasesLeft);
        setCurrentPhrase(newCurrentPhrase);
    }, [guessedPhrases]);

    useEffect(() => {
        if (currentMode === Rounds.EndGame) {
            clearInterval(timerInterval.current);
            timerInterval.current = undefined;
            setSeconds(undefined);
            getRanking();
        }
    }, [currentMode]);

    useEffect(() => {
        if (!currentPresenter) {
            return;
        }
        if (currentMode === Rounds.EndGame) {
            clearInterval(timerInterval.current);
            timerInterval.current = undefined;
        }

        if (gameStarted) {
            setPresenterModalVisible(true);
        } else {
            changeModeModal(currentMode, t('describingMode'), t('step6des'));
        }

    }, [currentPresenter, gameStarted]);

    useEffect(() => {
        if (mode === GameModes.Classic) {
            getPhrasesFromAPI();
        } else {
            getPhrasesForQuickMode();
        }

    }, []);

    const presenterModalOkCallback = useCallback(async () => {
        if (guessedPhrases.length === allPhrases.length) {
            guessedPhrases.length = 0;
            const newCurrentPhrase = pickRandomPhrase(allPhrases);
            setCurrentPhrase(newCurrentPhrase);
        } else {
            const phrasesLeft = getPhrasesToDraw();
            const newCurrentPhrase = pickRandomPhrase(phrasesLeft);
            setCurrentPhrase(newCurrentPhrase);
        }

        setSeconds(location.state?.roundSeconds ?? DEFAULT_ROUND_SECONDS);
        setPresenterModalVisible(false);
    }, [allPhrases, guessedPhrases]);

    const moveToNextRound = () =>{
        const shuffledPhrases = shuffleArray(allPhrases);
        setAllPhrases(shuffledPhrases);
    };

    const changeModeModal = (mode?: Rounds, modeLabel?: string, content?: string) => {
        if (mode === Rounds.EndGame) {
            return;
        }
        setAlertContent({
            header: '',
            message: modeLabel + '\n\n' + content ?? '',
            supportedOrientations: ["landscape"],
            showHintIcon: true,
            okAction: {
                action: () => {
                    if (mode === Rounds.Describe) {
                        setModeChangedModalVisible(false);
                        setGameStarted(true);
                        return;
                    }
                    setModeChangedModalVisible(false);
                    setSeconds(0);
                    moveToNextRound();
                },
                label: okBtnLabel
            },
        });
        setModeChangedModalVisible(true);
    }

    const getPhrasesFromAPI = () => {

        getPhrases(gameId).then((response) => {
            if (response?.phrases) {
                setAllPhrases(response.phrases);
            }
        });

        // TODO: dev only
        //setAllPhrases(["Testowa fraza", "Jacek Soplica", "Shrek", "Królewna Fiona", "Hnatkowice", "Biała Podlaska", "Tdsad"]);
    }

    const getPhrasesForQuickMode = () => {
        getRandomPhrases(location.state.phrasesNumber, i18n.language).then((response) => {
            if (response?.phrases) {
                setAllPhrases(response.phrases);
            }
        });
    }

    const switchTeams = (presenter: Presenter) => {
        const newPresenter = switchPresenter(teams, presenter);
        setCurrentPresenter(newPresenter);
    };

    const addPointsCallback = useCallback(() => {
        setAnimation(correctAnimation);
        setShowAnimation(true);
        const newGuessedPhrases = [...guessedPhrases, currentPhrase];
        setGuessedPhrases(newGuessedPhrases);

        // Set team points
        const newPoints = points;
        newPoints[currentPresenter.teamIndex] = newPoints[currentPresenter.teamIndex] + 1;
        setPoints(newPoints);

        // Set player points
        const newPlayerRanking = playerRanking;
        const newPlayerRankingIndex = newPlayerRanking.findIndex(x => x.playerName === currentPresenter.presenterName);
        newPlayerRanking[newPlayerRankingIndex].points = newPlayerRanking[newPlayerRankingIndex].points + 1;
        setPlayerRanking(newPlayerRanking);

    }, [currentPhrase, guessedPhrases, points, currentPresenter, playerRanking]);

    const wrongAnswerCallback = useCallback(() => {
        setAnimation(incorrectAnimation);
        setShowAnimation(true);
        const phrasesLeft = getPhrasesToDraw();
        const newCurrentPhrase = pickRandomPhrase(phrasesLeft, currentPhrase);
        setCurrentPhrase(newCurrentPhrase);

        // Set team points
        const newPoints = points;
        newPoints[currentPresenter.teamIndex] = newPoints[currentPresenter.teamIndex] - 1;
        setPoints(newPoints);

        // Set player points
        const newPlayerRanking = playerRanking;
        const newPlayerRankingIndex = newPlayerRanking.findIndex(x => x.playerName === currentPresenter.presenterName);
        newPlayerRanking[newPlayerRankingIndex].points = newPlayerRanking[newPlayerRankingIndex].points - 1;
        setPlayerRanking(newPlayerRanking);

        if (allPhrases.length - guessedPhrases.length <= 1) {
            setSeconds(0);
        }

    }, [currentPhrase,allPhrases, guessedPhrases, points, currentPresenter, playerRanking]);

    const skipCallback = useCallback(() => {
        if (!skips) {
            return;
        }
        const newSkips = skips;
        newSkips[currentPresenter.teamIndex] = newSkips[currentPresenter.teamIndex] - 1;
        setSkips(newSkips);

        const phrasesLeft = getPhrasesToDraw();
        const newCurrentPhrase = pickRandomPhrase(phrasesLeft, currentPhrase);
        setCurrentPhrase(newCurrentPhrase);
    }, [currentPhrase, guessedPhrases, currentPresenter, skips, allPhrases]);

    const getPhrasesToDraw = useCallback(() => {
        const phrasesToPick = allPhrases.slice();
        for (const element of guessedPhrases) {
            const index = phrasesToPick.indexOf(element);
            if (index !== -1) {
                phrasesToPick.splice(index, 1);
            }
        }

        return phrasesToPick;
    }, [allPhrases, guessedPhrases]);

    const getRanking = useCallback(() => {
        const teamIndices = Array.from(points.keys())
            .sort((a, b) => points[b] - points[a]);

        const ranking: TeamRankingModel[] = teamIndices.map((index, rank) => ({
            teamName: teams[index].teamName,
            score: points[index],
            rank: rank + 1
        }));

        const playerIndices = playerRanking.sort((a, b) => b.points - a.points);

        const playerRankingSorted: PlayerRankingModel[] = playerIndices.map((player, index) => ({
            playerName: player.playerName,
            score: player.points,
            rank: index + 1
        }));

        const result: Ranking = {
            teamRanking: ranking,
            playerRanking: playerRankingSorted
        };

        navigate(Routes.Ranking, {
            state: result
        });
    }, [points, playerRanking, navigate, teams]);

    const openPauseModal = useCallback(() => {
        clearInterval(timerInterval.current);
        setPauseModalVisible(true);
    }, [seconds]);

    const onResumeModal = useCallback(() => {
        setPauseModalVisible(false);
        if (seconds) {
            setSeconds(seconds + 1);
        }
    }, [seconds]);

    const onQuitModal = () => {
        setAlertContent({
            header: alertTitle,
            message: alertAllChangesLost,
            supportedOrientations: ["landscape"],
            showHintIcon: false,
            okAction: {
                action: async () => {
                    setIsConfirmModalVisible(false);
                    setPauseModalVisible(false);

                    // TODO: Reimplement
                    if (mode === GameModes.Classic) {
                        //await deleteGameData(gameId);
                    }

                    navigate(Routes.Welcome);
                },
                label: okBtnLabel
            },
            cancelAction: {
                label: alertCancel,
                action: () => {
                    setIsConfirmModalVisible(false);
                }
            }
        });
        setIsConfirmModalVisible(true);
    }

    return <>
        <GameView
            teams={teams}
            maxSkips={maxSkips}
            skipsLeft={skips ? skips[currentPresenter.teamIndex] : 0}
            currentPresenterLabel={presenterText}
            pauseModalVisible={pauseModalVisible}
            openPauseModal={openPauseModal}
            resumeModal={onResumeModal}
            onGameQuit={onQuitModal}
            okBtnLabel={startBtnLabel}
            pointsLabel={pointsLabel}
            nextRoundLabel={nextRoundLabel}
            isModeChangedVisible={modeChangedModalVisible}
            isSkipPossible={(skips ? skips[currentPresenter.teamIndex] > 0 : false) && (allPhrases.length - guessedPhrases.length > 1)}
            points={points}
            roundCompleted={guessedPhrases.length === allPhrases.length}
            presenterModalVisible={presenterModalVisible}
            presenterModalOkCallback={presenterModalOkCallback}
            currentModeLabel={currentModeLabel}
            alertContent={alertContent}
            isConfirmModalVisible={isConfirmModalVisible}
            seconds={formatTime(seconds)}
            presenter={currentPresenter}
            currentPhrase={currentPhrase}
            wrongAnswerCallback={wrongAnswerCallback}
            skipCallback={skipCallback}
            addPointsCallback={addPointsCallback}
        />
        {
            showAnimation &&
                <Lottie
                    animationData={animation}
                    style={{
                        position: 'absolute',
                        top: '2rem',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                    loop={false}
                    onComplete={() => setShowAnimation(false)}/>
        }
    </>
}