import {GameSettingsProps} from "./GameSettings.props";
import React from "react";
import {Link, Slider, Stack, Typography} from "@mui/material";
import {MainStack, PrimaryButton} from "../../styled-components/styledComponents";
import {
    MAX_PHRASES_PER_CATEGORY,
    MAX_ROUND_TIME,
    MAX_SKIPS, MIN_PHRASES_PER_CATEGORY,
    MIN_ROUND_TIME,
    MIN_SKIPS
} from "../../constans/rules";

export const GameSettingsView: React.FC<GameSettingsProps> = ({
    header,
    subHeader,
    confirmButtonLabel,
    cancelButtonLabel,
    onShowHints,
    onConfirm,
    onCancel,
    onRoundSecondsChange,
    onPhrasePerCategoryChange,
    onSkipsChange,
    skipsLabel,
    roundTimeSecondsLabel,
    phrasesPerCategoryLabel,
    phrasesPerCategory,
    skips,
    roundTimeSeconds,
    disableConfirmButton
  }) => {
    return <MainStack spacing={2} alignItems={ 'center'}>
        <Typography
            align={'center'}
            variant={'h5'}
        >
            {header}
        </Typography>
        <Link>
            <Typography
                align={'center'}
                variant={'h6'}
                onClick={onShowHints}
            >
                {subHeader}
            </Typography>
        </Link>
        <Stack spacing={2} alignItems={'center'}>
            <Typography variant="body1">{skipsLabel}</Typography>
            <Slider
                onChange={(event, value) => onSkipsChange(value as number)}
                min={MIN_SKIPS}
                max={MAX_SKIPS}
                step={1}
                value={skips}
                valueLabelDisplay="on"
                sx={{
                    width: 250
                }}
            />

            <Typography variant="body1">{roundTimeSecondsLabel}</Typography>
            <Slider
                onChange={(event, value) => onRoundSecondsChange(value as number)}
                min={MIN_ROUND_TIME}
                max={MAX_ROUND_TIME}
                step={1}
                value={roundTimeSeconds}
                valueLabelDisplay="on"
                sx={{
                    width: 250
                }}
            />

            <Typography variant="body1">{phrasesPerCategoryLabel}</Typography>
            <Slider
                onChange={(event, value) => onPhrasePerCategoryChange(value as number)}
                min={MIN_PHRASES_PER_CATEGORY}
                max={MAX_PHRASES_PER_CATEGORY}
                step={1}
                value={phrasesPerCategory}
                valueLabelDisplay="on"
                sx={{
                    width: 250
                }}
            />
        </Stack>
        <PrimaryButton
            variant="contained"
            style={{
                width: 200
            }}
            onClick={onConfirm}
            disabled={disableConfirmButton}
            >
            {confirmButtonLabel}
        </PrimaryButton>
        <PrimaryButton
            variant="contained"
            style={{
                width: 200
            }}
            onClick={onCancel}
        >
            {cancelButtonLabel}
        </PrimaryButton>
    </MainStack>
}