import React from "react";
import {DrawnTeamsProps} from "./DrawnTeams.props";
import {Team} from "../../models/team";
import {Link, List, ListItem, Stack, Typography} from "@mui/material";
import {MainStack, PrimaryButton, PrimaryInput, RoundedCard} from "../../styled-components/styledComponents";
import {teamColors} from "../../models/teamColots";
import {BLUE_TEAM_BORDER} from "../../themes/colors";

export const DrawnTeamsView: React.FC<DrawnTeamsProps> = ({
  teams,
  confirmButtonLabelReady,
  confirmButtonLabelWaiting,
  cancelButtonLabel,
  isReady,
  areTeamNamesEntered,
  onCancel,
  onConfirm,
  onTeamNameChanged,
  teamPlaceholder,
  header,
  subHeader,
  onShowHints
}) => {
    const renderTeamView = ({ item, index }: { item: Team, index: number }) => {
        return <RoundedCard
            variant="outlined"
            sx={{
                backgroundColor: teamColors[index].background,
                border: 'solid',
                borderWidth: '2px',
                borderColor: teamColors[index].border,
            }}
            key={index}>
            <PrimaryInput
                onChange={e => onTeamNameChanged(e.target.value, index)}
                slotProps={{
                    htmlInput: {
                        maxLength: 20
                    }
                }}
                value={item.teamName}
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                }}
                placeholder={teamPlaceholder + (index + 1)}
            />
            <List>
                {
                    item.users.map((player, index) => (
                        <ListItem key={index} sx={{
                            padding: 0,
                            paddingLeft: 2,
                        }}>
                            <Typography fontSize={"small"}>{index + 1 + ". " + player}</Typography>
                        </ListItem>
                    ))
                }
            </List>
        </RoundedCard>
    }

    return <MainStack spacing={2} alignItems={ 'center'}>
            <Typography
                align={'center'}
                variant={'h5'}
            >
                {header}
            </Typography>
            <Link>
                <Typography
                    align={'center'}
                    variant={'h6'}
                    onClick={onShowHints}
                >
                    {subHeader}
                </Typography>
            </Link>
            <>
                {
                    teams.map((team, index) => renderTeamView({
                        item: team,
                        index
                    }))
                }
            </>
            <PrimaryButton
                variant="contained"
                style={{
                    width: 200,
                    marginTop: 120
                }}
                disabled={!isReady || !areTeamNamesEntered}
                onClick={onConfirm}>
                {isReady ? confirmButtonLabelReady : confirmButtonLabelWaiting}
            </PrimaryButton>
            <PrimaryButton
                variant="contained"
                style={{
                    width: 200
                }}
                onClick={onCancel}
            >
                {cancelButtonLabel}
            </PrimaryButton>
        </MainStack>
}