export const MIN_PLAYERS = 4;
export const MAX_PLAYERS = 20;
export const MAX_SKIPS = 10;
export const MIN_SKIPS = 0;
export const MIN_ROUND_TIME = 20;
export const MAX_ROUND_TIME = 180;
export const MIN_PHRASES_PER_CATEGORY = 1;
export const MAX_PHRASES_PER_CATEGORY = 5;
export const MIN_CATEGORIES = 1;
export const MAX_CATEGORIES = 3;
export const MIN_PLAYERS_FOR_3_TEAMS_SUGGESTION = 9;
export const SUGGESTED_PHRASES_PER_PLAYER = 6;
export const MAX_PHRASES_PER_PLAYER = 9;
export const MIN_PHRASES_PER_PLAYER = 1;

export const PLAYER_NAME_MAX_LENGTH = 10;
export const LETTERS_DIGITS_ONLY_REGEX = /^[a-zA-Z0-9]*$/;
export const DIGITS_ONLY_REGEX = /^[0-9]*$/;
export const DEFAULT_ROUND_SECONDS = 60;
export const DEFAULT_SKIPS_PER_TEAM = 2;
export const DEFAULT_PHRASES_PER_CATEGORY = 3;
export const DEFAULT_TEAMS = 2;
export const INCREASED_TEAMS = 3;

export enum Rounds {
    Describe,
    OneWord,
    Show,
    EndGame
}