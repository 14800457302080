import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {
    DEFAULT_TEAMS, INCREASED_TEAMS,
    MAX_PHRASES_PER_PLAYER,
    MIN_PHRASES_PER_PLAYER,
    MIN_PLAYERS_FOR_3_TEAMS_SUGGESTION,
    SUGGESTED_PHRASES_PER_PLAYER
} from "../../constans/rules";
import {Routes} from "../../constans/routes";
import {GameModes} from "../../models/gameMode";
import {PhrasesNumberView} from "./PhrasesNumberView";
import {BaseModal} from "../BaseModal/BaseModal";
import {BaseModalProps} from "../BaseModal/BaseModal.props";

export const PhrasesNumber: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const suggestedNumber = location.state.playerNames.length * SUGGESTED_PHRASES_PER_PLAYER;
    const phraseNumberLabel = t('phrasesLabel').replace('{1}', suggestedNumber.toString());
    const cancelButtonLabel = t('previousBtn');
    const nextButtonLabel = t('nextBtn');
    const maxPhrasesNumber = location.state.playerNames.length * MAX_PHRASES_PER_PLAYER;
    const minPhrasesNumber = location.state.playerNames.length * MIN_PHRASES_PER_PLAYER;
    const header = t('setNumberOfPhrases');
    const subHeader = t('hintsBtn');
    const modalHintContent = t('phrasesNumberHint');

    const [phrasesNumber, setPhrasesNumber] = useState(location.state.phrasesNumber ?? suggestedNumber);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<BaseModalProps>({
        header: '',
        message: '',
        showHintIcon: false,
        okAction: {
            action: () => setIsModalVisible(false),
            label: t('defaultOkBtn')
        }
    });

    const proceed = (teamsNumber: number) => {
        // Add modal asking for teams number, copy -> paste from SharePhraseLinks
        navigate(Routes.DrawnTeams, {
            state: {
                ...location.state,
                teamsNumber: teamsNumber,
                phrasesNumber: phrasesNumber,
                mode: GameModes.Quick
            }
        });
    };

    const askTeamsNumber = () => {
        if (location.state.playerNames.length < MIN_PLAYERS_FOR_3_TEAMS_SUGGESTION) {
            proceed(DEFAULT_TEAMS);
            return;
        }

        setModalContent({
            header: t('selectTeamsNumberHeader'),
            message: t('selectTeamsNumberMessage')
                .replace('{0}', MIN_PLAYERS_FOR_3_TEAMS_SUGGESTION.toString())
                .replace('{1}', DEFAULT_TEAMS.toString())
                .replace('{2}', INCREASED_TEAMS.toString()),
            showHintIcon: false,
            okAction: {
                action: () => {
                    setIsModalVisible(false);
                    proceed(INCREASED_TEAMS);
                },
                label: t('selectTeamsBtn')
                    .replace('{0}', INCREASED_TEAMS.toString())
            },
            cancelAction: {
                label:t('selectTeamsBtn')
                    .replace('{0}', DEFAULT_TEAMS.toString()),
                action: async () => {
                    setIsModalVisible(false);
                    proceed(DEFAULT_TEAMS);
                }
            }
        });

        setIsModalVisible(true);
    };

    const onHintClicked = () =>{
        setModalContent({
            header: '',
            message: modalHintContent,
            showHintIcon: true,
            okAction: {
                action: () => {
                    setIsModalVisible(false);
                },
                label: t('okBtn')
            }
        });
        setIsModalVisible(true);
    };

    const onCancel = () => {
        navigate(Routes.ModeSelection, {
             state: location.state
        });
    }

    const onChange = (event: Event, num: number | number[]) => {
        setPhrasesNumber(num as number);
    }

    return <>
        <PhrasesNumberView
            confirmButtonLabel={nextButtonLabel}
            cancelButtonLabel={cancelButtonLabel}
            onConfirm={askTeamsNumber}
            maxPhrasesNumber={maxPhrasesNumber}
            minPhrasesNumber={minPhrasesNumber}
            header={header}
            subHeader={subHeader}
            onChange={onChange}
            onShowHints={onHintClicked}
            onCancel={onCancel}
            phrasesNumber={phrasesNumber}
            phrasesNumberLabel={phraseNumberLabel}
        />
        <BaseModal {...modalContent} isVisible={isModalVisible} />
    </>
}