import {ModeSelectionProps} from "./ModeSelection.props";
import React from "react";
import {Link, Stack, Typography} from "@mui/material";
import {MainStack, PrimaryButton} from "../../styled-components/styledComponents";
import Lottie from "lottie-react";
import rocketAnimation from "../../assets/animations/rocket.json";
import writingAnimation from "../../assets/animations/writing.json";
import {
    CLASSIC_MODE_BACKGROUND,
    CLASSIC_MODE_BORDER,
    QUICK_PLAY_BACKGROUND,
    QUICK_PLAY_BORDER
} from "../../themes/colors";

export const ModeSelectionView: React.FC<ModeSelectionProps> = ({
    classicModeLabel,
    quickPlayModeLabel,
    cancelButtonLabel,
    header,
    subHeader,
    onCancel,
    onShowHints,
    onClassicModeSelected,
    onQuickPlayModeSelected
}) => {
    return <MainStack
        spacing={2}
        alignItems={ 'center'}>
        <Typography
            align={'center'}
            variant={'h5'}
        >
            {header}
        </Typography>
        <Link>
            <Typography
                align={'center'}
                variant={'h6'}
                onClick={onShowHints}
            >
                {subHeader}
            </Typography>
        </Link>
        <PrimaryButton
            variant="contained"
            style={{
                width: 250,
                marginTop: 120,
                backgroundColor: CLASSIC_MODE_BACKGROUND,
                borderColor: CLASSIC_MODE_BORDER
            }}
            onClick={onClassicModeSelected}>
            {classicModeLabel}
            <Lottie style={{ width: '50px'}} animationData={writingAnimation} />
        </PrimaryButton>
        <PrimaryButton
            variant="contained"
            style={{
                width: 250,
                backgroundColor: QUICK_PLAY_BACKGROUND,
                borderColor: QUICK_PLAY_BORDER
            }}
            onClick={onQuickPlayModeSelected}
        >
            {quickPlayModeLabel}
            <Lottie style={{ width: '50px'}} animationData={rocketAnimation} />
        </PrimaryButton>
        <PrimaryButton
            variant="contained"
            style={{
                width: 200,
                marginTop: 200
            }}
            onClick={onCancel}
        >
            {cancelButtonLabel}
        </PrimaryButton>
    </MainStack>
}