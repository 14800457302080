import { useTranslation } from "react-i18next";
import React from "react";
import {Stack} from "@mui/material";

export const ErrorPage: React.FC = () => {
    const { t } = useTranslation();
    
    return <Stack spacing={2} alignItems="center" sx={{
        padding: "2rem",
    }}>
        <h1>{t("errorHeader")}</h1>
        <h4>{t("errorSubHeader")}</h4>
    </Stack>
}