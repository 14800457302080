import React from "react";
import {RulesView} from "./RulesView";
import {useNavigate} from "react-router-dom";
import {Routes} from "../../constans/routes";
import {useTranslation} from "react-i18next";
import {Rule, RuleType} from "../../models/rule";

export const Rules: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const cancelButtonLabel = t('previousBtn');
    const header = t('step0');
    const ruleNames: string[] = [t('describingMode'), t('oneWordMode'), t('showingMode')];
    const steps: Rule[] = [{
        header: t('step1'),
        content: t('step1des'),
        type: RuleType.HeaderWithContent
    }, {
        header: t('step2'),
        content: t('step2des'),
        type: RuleType.HeaderWithContent
    }, {
        header: t('step3'),
        content: t('step3des'),
        type: RuleType.HeaderWithContent,
    }, {
        content: t('quickModeDisclaimer'),
        type: RuleType.ContentOnly
    }, {
        header: t('step4'),
        content: t('step4des'),
        type: RuleType.HeaderWithContent
    }, {
        header: t('step5'),
        content: t('step5des'),
        type: RuleType.HeaderWithContent
    }, {
        header: t('step6'),
        content: t('step6des'),
        type: RuleType.RoundDescription,
        roundNumber: 1
    }, {
        header: t('step7'),
        content: t('step7des'),
        type: RuleType.RoundDescription,
        roundNumber: 2
    }, {
        header: t('step8'),
        content: t('step8des'),
        type: RuleType.RoundDescription,
        roundNumber: 3
    }, {
        content: t('step9des'),
        type: RuleType.ContentOnly
    }];

    const cancelCallback = () => navigate(Routes.Welcome);

    return <RulesView
        onCancel={cancelCallback}
        ruleNames={ruleNames}
        cancelButtonLabel={cancelButtonLabel}
        header={header}
        steps={steps} />
}