import {Box, Stack, Typography} from "@mui/material"
import { WelcomeProps } from "./Welcome.props"
import image from "../../assets/logo_transparent.png";
import shineAnimation from "../../assets/animations/shine.json";
import React from "react";
import Lottie from "lottie-react";
import {MainStack, PrimaryButton} from "../../styled-components/styledComponents";

export const WelcomeView: React.FC<WelcomeProps> = ({
    onStartClick,
    onAuthorsClick,
    onHowToPlayClick,
    title, 
    startButtonLabel,
    authorsButtonLabel,
    howToPlayButtonLabel,
}) => {
    return <MainStack
            sx={{
                display: "flex",
                alignItems: "center",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center"
            }}
            spacing={2}
            alignItems={ 'center'}
        >
        <Stack spacing={2} alignItems={'center'} sx={{ height: "80vh" }}>
            <img style={{
                height: "20svh"
            }} src={image} alt="Garnek game logo"/>
            <Typography variant="h4" gutterBottom>{title}</Typography>

            <Stack sx={{height: "20vh", justifyContent: "space-between"}}>
                <PrimaryButton style={{width: 200}} variant="contained" onClick={onStartClick}>{startButtonLabel}</PrimaryButton>
                <PrimaryButton style={{width: 200}} variant="contained" onClick={onHowToPlayClick}>{howToPlayButtonLabel}</PrimaryButton>
                <PrimaryButton style={{width: 200}} variant="contained" onClick={onAuthorsClick}>{authorsButtonLabel}</PrimaryButton>
            </Stack>
        </Stack>
    <Box position="absolute" top={0}>
        <Lottie animationData={shineAnimation} autoplay loop />
    </Box>
    </MainStack>

}