import { useTranslation } from "react-i18next"
import React from "react";
import {Stack} from "@mui/material";

export const PhrasesSaved: React.FC = () => {
    const { t } = useTranslation();

    return <Stack spacing={2} alignItems="center" sx={{
        padding: "2rem"
    }}>
        <h1>{t("savedHeader")}</h1>   
        <h4>{t("savedSubHeader")}</h4>
    </Stack>
}
