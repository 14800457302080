import {Button, Link, List, ListItem, Stack, Typography} from "@mui/material";
import { EnterPlayerNamesProps } from "./EnterPlayerNames.props";
import { PLAYER_NAME_MAX_LENGTH } from "../../constans/rules";
import React from "react";
import {MainStack, PrimaryButton, PrimaryInput} from "../../styled-components/styledComponents";

export const EnterPlayerNamesView: React.FC<EnterPlayerNamesProps> = ({
    confirmButtonLabel,
    cancelButtonLabel,
    onConfirm,
    onCancel,
    onPlayersNumberIncreased,
    onPlayersNumberDecreased,
    increasePlayersNumberButtonDisabled,
    decreasePlayersNumberButtonDisabled,
    header,
    subHeader,
    onNameChanged,
    playerNames,
    someNamesInvalid,
    playerPlaceholderLabel,
    addBtnLabel,
    removeBtnLabel,
    onShowHints
}) => {

    const renderInput = ({ item, index }: { item: string, index: number }) => (
        <ListItem key={index}>
            <PrimaryInput
                key={index}
                onChange={text => onNameChanged(text.target.value, index)}
                style={{
                    width: 250
                }}
                placeholder={playerPlaceholderLabel + (index + 1)}
                slotProps={{
                    htmlInput: {
                        maxLength: PLAYER_NAME_MAX_LENGTH
                    }
                }}
                value={item}
            />
        </ListItem>
    );

    return <MainStack spacing={2} alignItems={ 'center'}>
            <Typography
                align={'center'}
                variant={'h5'}
                >
                {header}
            </Typography>
            <Link onClick={onShowHints}>
                <Typography
                    align={'center'}
                    variant={'h6'}

                >
                    {subHeader}
                </Typography>
            </Link>
            <List>
            {
                playerNames.map((item, index) => renderInput({
                    item,
                    index
                }))
            }
            <ListItem>
                <Button 
                    onClick={onPlayersNumberIncreased} 
                    disabled={increasePlayersNumberButtonDisabled}
                    style={{
                        borderWidth: 2,
                        width: 100,
                        marginRight: 5,
                        marginLeft: 25
                    }}>
                    {addBtnLabel}
                </Button>
                <Button 
                    onClick={onPlayersNumberDecreased} 
                    disabled={decreasePlayersNumberButtonDisabled}
                    style={{
                        borderWidth: 2,
                        width: 100,
                        marginLeft: 5,
                        marginRight: 25
                    }}>
                    {removeBtnLabel}
                </Button>
            </ListItem>
            </List>
            <PrimaryButton
                variant="contained"
                style={{
                    width: 200
                }}
                disabled={someNamesInvalid}
                onClick={onConfirm}>
                {confirmButtonLabel}
            </PrimaryButton>
            <PrimaryButton
                variant="contained"
                style={{
                    width: 200
                }}
                onClick={onCancel}
                >
                {cancelButtonLabel}
            </PrimaryButton>
        </MainStack>
}